import React from 'react';
import footerMenuItems from 'constants/footerMenuItems';
import FooterCopyright from 'components/FooterCopyright';
import ContentContainer from 'components/ContentContainer';
import FooterAdvantages from 'components/FooterAdvantages';
import Button from 'components/Button';

import footerLogo from 'images/logo_alpha.png';

import styles from './Footer.module.scss';

const Footer = () => {
  const footerMenu = footerMenuItems.map((footerColumn) => {
    return (
      <div className={styles.footerColumn} key={footerColumn.title}>
        <h3 className={styles.footerColumnTitle}>{footerColumn.title}</h3>

        {footerColumn.columnItems && (
          <div className={styles.footerColumnItems}>
            {footerColumn.columnItems.map((item) => (
              <div className={styles.footerColumnItem} key={item.title}>
                <Button
                  link={item.href}
                  className={styles.footerColumnLink}
                  title={item.title}
                />
              </div>
            ))}
          </div>
        )}

        {footerColumn.columnIcons && (
          <div className={styles.footerColumnIcons}>
            {footerColumn.columnIcons.map((icon, i) => (
              <span className={styles.footerColumnIcon} key={i}>
                {icon}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  });

  return (
    <footer className={styles.footer}>
      <ContentContainer className={styles.container}>
        {footerMenu && <div className={styles.columns}>{footerMenu}</div>}
        <FooterAdvantages />
        <div className={styles.logos}>
          <div className={styles.logo}>
            <img src={footerLogo} alt="Funeral Marketplace Logo" />

            <div className={styles.logoCaption}>
              <div className={styles.logoTitle}>FUNERAL MARKETPLACE</div>
              <div className={styles.logoSubtitle}>Der Marktplatz für den Abschied</div>
              <div className={styles.logoDescription}>
                <span>für</span> Hersteller · Dienstleister · Privatkunden
              </div>
            </div>
          </div>

          <div className={styles.slogan}>For more respect and fairness</div>
        </div>
      </ContentContainer>

      <FooterCopyright />
    </footer>
  );
};

export default Footer;
