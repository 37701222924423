import React from 'react';

import IconDeliveryGold from 'images/svg/DeliveryGold.svg';
import IconLockGold from 'images/svg/LockGold.svg';
import IconSslGold from 'images/svg/SslGold.svg';
import IconRibbonGold from 'images/svg/RibbonGold.svg';

import styles from './FooterAdvantages.module.scss';

const advantages = [
  {
    title: 'Schnell',
    description: 'Schnelle Lieferung',
    icon: IconDeliveryGold
  },
  {
    title: 'Sicher',
    description: 'Datenschutzgarantie',
    icon: IconLockGold
  },
  {
    title: 'Bestens',
    description: 'Durchweg positiv bewertet',
    icon: IconRibbonGold
  },
  {
    title: 'Geschützt',
    description: 'Sichere Zahlung mit SSL-Verschlüsselung',
    icon: IconSslGold
  }
];

const FooterAdvantages = () => {
  return (
    <div className={styles.container}>
      {advantages.map((advantage) => {
        const IconComponent = advantage.icon;

        return (
          <div key={advantage.title} className={styles.advantage}>
            <div className={styles.iconContainer}>
              <IconComponent />
            </div>

            <h4 className={styles.title}>
              <span className={styles.titleText}>
                {advantage.title}
                <span className={styles.checkmark} />
              </span>
            </h4>

            <p className={styles.description}>{advantage.description}</p>
          </div>
        );
      })}
    </div>
  );
};

export default FooterAdvantages;
