import React from 'react';

import IconPay from 'images/svg/IconPay.svg';
import IconMastercard from 'images/svg/IconMastercard.svg';
import IconSepa from 'images/svg/IconSepa.svg';
import IconVisa from 'images/svg/IconVisa.svg';
import IconIos from 'images/svg/IconIos.svg';
import { PRODUCTION_HOSTNAME } from './common';
import getFmpUrl from 'functions/urls/getFmpUrl';

const footerMenuItems = [
  {
    title: 'Service',
    columnItems: [
      {
        title: 'Häufige Fragen (FAQ)',
        href: `//${PRODUCTION_HOSTNAME}/faq`
      },
      {
        title: 'Kontakt & Support',
        href: `//${PRODUCTION_HOSTNAME}/contact`
      },
      {
        title: 'Konditionen Anbieter',
        href: '/hersteller-konditionen'
      }
    ]
  },
  {
    title: 'Rechtliches',
    columnItems: [
      { title: 'Impressum', href: `//${PRODUCTION_HOSTNAME}/imprint` },
      { title: 'AGB', href: `//${PRODUCTION_HOSTNAME}/terms` },
      { title: 'Datenschutz', href: `//${PRODUCTION_HOSTNAME}/privacy` },
      { title: 'Widerruf', href: `//${PRODUCTION_HOSTNAME}/cancellation` },
      { title: 'Zahlung & Versand', href: `//${PRODUCTION_HOSTNAME}/payment-methods` }
    ]
  },
  {
    title: 'Mediales',
    columnItems: [
      { title: 'iOS App', href: getFmpUrl('ios-app') },
      {
        title: 'Marketinganfragen',
        href: getFmpUrl('contact/press')
      },
      {
        title: 'Presseanfragen',
        href: getFmpUrl('contact/press')
      }
    ],
    columnIcons: [
      <a
        href="//itunes.apple.com/app/apple-store/id1412737862?pt=118572395&ct=about.convela.cloud%2Fios-app&mt=8"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconIos />
      </a>
    ]
  },
  {
    title: 'Zahlungsarten',
    columnItems: [
      {
        title: 'Kreditkarte',
        href: getFmpUrl('payment-methods')
      },
      {
        title: 'SEPA-Lastschrift',
        href: getFmpUrl('payment-methods')
      },
      {
        title: 'SOFORT Überweisung',
        href: getFmpUrl('payment-methods')
      }
    ],
    columnIcons: [<IconPay />, <IconVisa />, <IconMastercard />, <IconSepa />]
  },
  {
    title: 'Sonstiges',
    columnItems: [
      {
        title: 'Jobs',
        href: getFmpUrl('jobs')
      },
      {
        title: 'Freunde einladen',
        href: getFmpUrl('invite-friends')
      },
      {
        title: 'Businesskunden werben',
        href: getFmpUrl('invite-business-users')
        // },
        // {
        //   title: 'Für Investoren',
        //   href: 'https://about.convela.cloud/for-investors'
        // },
        // {
        //   title: 'Foreign Agents ',
        //   href: 'https://about.convela.cloud/welcomeforeignagent'
      }
    ]
  }
];

export default footerMenuItems;
