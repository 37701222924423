/**
 * Layout component should ALWAYS come first in imports
 * since it holds global styles and css reset which should be applied first
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import 'reset-css';

import 'styles/index.scss';

import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="page-container" id="page-start">
      <Header siteTitle={data.site.siteMetadata?.title || 'Title'} />

      <main>{children}</main>

      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
