import PropTypes from 'prop-types';

const tabletPortrait = 768;
const tabletLandscape = 1024;
const containerWidth = 1152;
const laptop = 1366;
const phoneLandScape = 480;
const largePhoneLandscape = 640;

const mapSizesToProps = ({ width }) => ({
  isMobileCheckout: width <= 660,
  isTablet: width >= tabletPortrait && width <= tabletLandscape,
  upToPhoneLandscapeInclusive: width <= phoneLandScape,
  upToPhoneLandscape: width < phoneLandScape,
  upToLargePhoneLandscape: width < largePhoneLandscape,
  upToLargePhoneLandscapeInclusive: width <= largePhoneLandscape,
  upToTabletPortrait: width < tabletPortrait,
  upToTabletPortraitInclusive: width <= tabletPortrait,
  upToTabletLandscape: width <= tabletLandscape,
  upToLaptop: width <= laptop,
  greaterThanTabletLandscape: width > tabletLandscape,
  greaterThanTabletPortrait: width > tabletPortrait,
  upToContainerWidth: width < containerWidth,
  upToContainerWidthInclusive: width <= containerWidth,
  greaterThanContainerWidth: width > containerWidth
});

export const propTypes = Object.keys(mapSizesToProps({ width: 1 })).reduce((acc, key) => {
  acc[key] = PropTypes.bool;

  return acc;
}, {});

export default mapSizesToProps;
