import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import ContentContainer from 'components/ContentContainer';
import IconPhone from 'images/svg/IconPhone.svg';
// import IconHeartOutlined from 'images/svg/IconHeartOutlined.svg';
import IconShoppingCart from 'images/svg/IconShoppingCart.svg';
import IconEditUser from 'images/svg/IconEditUser.svg';
import fmpLogo from 'images/logo.png';

import styles from './Header.module.scss';
import getFmpUrl from 'functions/urls/getFmpUrl';
import HeaderMenu from 'components/HeaderMenu';

const menuItems = [
  { path: getFmpUrl('highlights'), name: 'Magazin' },
  { path: getFmpUrl('categories'), name: 'Marktplatz' },
  { path: getFmpUrl('mandate-register'), name: 'Zentralregister' },
  {
    path: '//b2b.fmp.rip?utm_source=about.convela.cloud&utm_medium=nav-bar',
    name: 'Für Unternehmen',
    isExternal: true
  }
];

const Header = ({ location }) => {
  const renderHeaderIcons = () => {
    return (
      <div className={styles.headerIcons}>
        {/* <a className={styles.headerLink} href={getFmpUrl('watchlist')}>
          <IconHeartOutlined className={cn(styles.headerIcon, styles.headerIconHeart)} />
          <div>Merkliste</div>
        </a> */}
        <a href={getFmpUrl('cart')} className={styles.headerLink}>
          <IconShoppingCart className={cn(styles.headerIcon, styles.headerIconCart)} />
          <div>Warenkorb</div>
        </a>
        <a href={getFmpUrl('login')} className={styles.headerLink}>
          <IconEditUser className={cn(styles.headerIcon, styles.headerIconEditUser)} />
          <div>Login</div>
        </a>
      </div>
    );
  };

  return (
    <div className={styles.sticky}>
      <div className={styles.announcement}>
        <span>
          Wenn Sie HILFE benötigen, dann beraten wir Sie gerne persönlich.&nbsp;
        </span>
        <div className={styles.phoneContainer}>
          <span>Rufen Sie uns an:</span>
          <IconPhone className={styles.phoneIcon} />
          <span className={styles.announcementPhone}>+49 345 7792 3807</span>
        </div>
      </div>

      <header className={styles.header}>
        <ContentContainer className={styles.headerContainer}>
          <Link to="/">
            <img
              className={styles.headerLogo}
              src={fmpLogo}
              alt="Funeral Marketplace Logo"
            />
          </Link>

          <HeaderMenu>
            <div className={styles.headerMenuItems}>
              {menuItems.map((item) =>
                item.path.startsWith('//') ? (
                  <a
                    href={item.path}
                    key={item.path}
                    className={cn(styles.headerMenuItem, {
                      [styles.headerMenuItemExternal]: item.isExternal
                    })}
                    target={item.isExternal ? '_blank' : ''}
                  >
                    {item.name}
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    key={item.path}
                    className={cn(styles.headerMenuItem, {
                      [styles.active]:
                        item.path.replace(/\//g, '') ===
                        location.pathname.replace(/\//g, '')
                    })}
                  >
                    {item.name}
                  </Link>
                )
              )}
            </div>

            {renderHeaderIcons()}
          </HeaderMenu>

          {renderHeaderIcons()}
        </ContentContainer>
      </header>
    </div>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired
};

export default (props) => (
  <Location>{(locationProps) => <Header {...locationProps} {...props} />}</Location>
);
