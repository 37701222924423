import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './ContentContainer.module.scss';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.object
};

const ContentContainer = ({ style, className, children }) => {
  return (
    <div className={cn(styles.container, className)} style={style}>
      {children}
    </div>
  );
};

ContentContainer.propTypes = propTypes;

export default ContentContainer;
