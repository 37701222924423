import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'gatsby';

import styles from './Button.module.scss';

const Button = ({
  className,
  type = 'unstyled',
  color,
  title,
  link,
  children,
  ...props
}) => {
  const isExternal = /^\/{2}/.test(link || '');
  const _className = cn(
    className,
    styles.button,
    styles['type_' + type],
    styles['color_' + color]
  );

  return link ? (
    isExternal ? (
      <a
        className={_className}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {title}
        {children}
      </a>
    ) : (
      <Link to={link} className={_className} {...props}>
        {title}
        {children}
      </Link>
    )
  ) : (
    <div className={_className} {...props}>
      {title}
      {children}
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

export default Button;
