import { withSizes } from 'react-sizes';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useRef, useLayoutEffect } from 'react';

import styles from '../Header/Header.module.scss';

import mapSizesToProps, { propTypes as mediaPropTypes } from 'utils/mediaqueries';

const propTypes = {
  children: PropTypes.node,
  ...mediaPropTypes
};

const HeaderMenu = ({ children, upToTabletLandscape, width, badgeCount }) => {
  const [isOpened, toggleMenu] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const menuElementRef = useRef(null);

  const resetMenuSize = () => {
    document.documentElement.style.height =
      isOpened && upToTabletLandscape ? `${window.innerHeight}px` : '';

    const menuElement = menuElementRef.current;
    let menuYPos = 0;

    if (menuElement) {
      menuYPos = menuElement.getBoundingClientRect().top;
    }

    const newMenuHeight = window.innerHeight - menuYPos;

    if (newMenuHeight !== menuHeight) {
      setMenuHeight(newMenuHeight);
    }
  };

  const handleToggleClick = () => {
    toggleMenu(!isOpened);

    document.documentElement.classList[isOpened ? 'remove' : 'add'](styles.menuOpened);

    resetMenuSize();
  };

  /*
    TODO adapt to gatsby
    Close menu on routes change
    if menu isOpened

  history.listen(() => {
    if (isOpened) {
      handleToggleClick();
    }
  }); */

  if (!isOpened && !upToTabletLandscape) {
    document.documentElement.classList.remove(styles.menuOpened);
  }

  useLayoutEffect(resetMenuSize, [isOpened, upToTabletLandscape, width]);

  return (
    <div className={styles.headerMenu}>
      <div className={styles.headerMenuToggleWrapper} onClick={handleToggleClick}>
        <div className={styles.headerMenuToggle}>{!isOpened && badgeCount}</div>
      </div>

      <div className={styles.headerMenuContentWrapper} ref={menuElementRef}>
        <div
          className={cn(styles.headerMenuContent, {
            [styles.opened]: upToTabletLandscape && isOpened
          })}
          style={upToTabletLandscape && menuHeight ? { height: menuHeight } : {}}
        >
          <div className={styles.headerMenuContentInner}>{children}</div>
        </div>
      </div>
    </div>
  );
};

HeaderMenu.propTypes = propTypes;

const mapSizesAndWidth = ({ width }) => ({ width, ...mapSizesToProps({ width }) });

export default withSizes(mapSizesAndWidth)(HeaderMenu);
