import React from 'react';

import ContentContainer from 'components/ContentContainer';

import styles from './FooterCopyright.module.scss';

const currentYear = new Date().getFullYear();

const FooterCopyright = () => (
  <div className={styles.copyright}>
    <ContentContainer>
      <div className={styles.copyrightOuter}>
        <div className={styles.copyrightInner}>
          Copyright © Funeral Marketplace {currentYear} ·{' '}
          <span className={styles.copyrightAccent}>All rights reserved</span>
        </div>
      </div>
    </ContentContainer>
  </div>
);

export default FooterCopyright;
